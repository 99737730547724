




































































    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { IDataTable } from '@/model/main/IDataTable';
    import { IHeaderTable } from '@/model/main/IHeaderTable';
    import { internet } from '@/utils/Internet';
    import Util from '@/utils/Util';
    import { Icon } from '@/model/util/Icon';

    @Component({
		name: 'dataTableSelection'
    })
    export default class dataTableSelection extends Vue {
        @Prop({
			type: String,
			required: true,
		})
		readonly filtroFechaInicial!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly filtroFechaFinal!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly filtroCallCenter!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly filtroCampania!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly filtroAgente!: string;
        @Prop({
			type: Array,
			required: true,
		})
		readonly datos!: Array<any>;
        //i18n
        public texto_atencion = this.$t("DataTableSelection.atencion");
        public btn_transcribir = this.$t("DataTableSelection.transcribir");
        public texto_confirmacion = this.$t("DataTableSelection.confirmacion");
        public btn_cerrar = this.$t("DataTableSelection.cerrar");
        public btn_continuar = this.$t("DataTableSelection.continuar");
        public noDataText = this.$t("DataTable.noDataText");
        public elementPerPage = this.$t("DataTable.elementPerPage");
		public countElementPage = this.$t("DataTable.countElementPage");
        public texto_campania = this.$t("DataTableSelection.campania");
        public texto_agente = this.$t("DataTableSelection.agente");
        public nombreAgente = this.$t("monitoreos.agenteData");
        public nombreAudio = this.$t("monitoreos.audioData");
        public campaniaAudio = this.$t("monitoreos.campaniaData");
        public resultadoTitle = this.$t("monitoreos.Resultado");
        


        public singleSelect = false;
        public editItem = false;
        public deleteItem = false;
        public dialog_transcrip = false;
        public Call_center = "";
        public total_transcript = 0;
        //public cc_transcript: Array<string> = [];
        //public cp_transcript: Array<string> = [];
        //public ag_transcript: Array<string> = [];
        public filtro_transcript: Array<string> = [];
        public disable_transcript = true;
        public selectedAudio = '';
        public rows: Array<IDataTable> = []
        public headers = [
            {text: this.nombreAgente as string, value: "Call_center"}, 
            //{text: this.texto_campania as string, value: "Campania"}, 
            //{text: this.texto_agente as string, value: "Agente"}, 
            {text: this.nombreAudio as string, value: "Ruta_sftp"},
            {text: this.campaniaAudio as string, value: "Total_audios"}, 
            {text: this.resultadoTitle as string, value: "Accion"}
        ]

        mounted(): void {
            if (this.datos.length > 0) {
                this.library();
            }
        }

        private library() {
            const data = this.datos;
            const dataTable: Array<IDataTable> = [];
            let todos = this.$t("DataTableSelection.todos") as string;
            let callCenters: Array<string> = [todos];
            let campanias: Array<string> = [todos];
            let agentes: Array<string> = [todos];
            let num = 0;
            for (let item of data) {
                //console.log(item)
                if (!callCenters.includes(item.Call_center)) {
                    callCenters.push(item.Call_center)
                }
                // if (!campanias.includes(item.Campania)) {
                //     campanias.push(item.Campania)
                // }
                // if (!agentes.includes(item.Agente)) {
                //     agentes.push(item.Agente)
                // }
                const row: IDataTable = {};
                row['Call_center'] = item.Call_center;
                // row['Campania'] = item.Campania;
                // row['Agente'] = item.Agente;
                row['Ruta_sftp'] = item.Ruta_sftp;
                row['Total_audios'] = item.Total_audios;
                row['Accion'] = "";
                row['Item_key'] = `fila${num}`;
                num +=1;
                if (this.filtroCallCenter != todos && item.Call_center != this.filtroCallCenter) {
                    continue;
                // } else if (this.filtroCampania != todos && item.Campania != this.filtroCampania) {
                //     continue;
                // } else if (this.filtroAgente != todos && item.Agente != this.filtroAgente) {
                //     continue;
                }
                dataTable.push(row);
            }
            this.$emit("load", {"callCenters": callCenters, "campanias": campanias, "agentes": agentes});
            this.rows = dataTable;
        }

        /*public pre_transcribir() {
            let total = 0;
            //let callCenters: Array<string> = [];
            //let campanias: Array<string> = [];
            //let agentes: Array<string> = [];
            let filtro: Array<string> = [];
            for (let item of this.selected) {
                total += item["Total_audios"];
                //if (!callCenters.includes(item["Call_center"])) {
                //    callCenters.push(item["Call_center"]);
                //}
                //if (!campanias.includes(item["Campania"])) {
                //    campanias.push(item["Campania"]);
                //}
                //if (!agentes.includes(item["Agente"])) {
                //    agentes.push(item["Agente"]);
                //}
                filtro.push(`${item["Call_center"]}::${item["Campania"]}::${item["Agente"]}`);
            }
            if (total > 0) {
                //this.cc_transcript = callCenters;
                //this.cp_transcript = campanias;
                //this.ag_transcript = agentes;
                this.filtro_transcript = filtro;
                this.total_transcript = total;
                this.dialog_transcrip = true;
            } else {
                console.log('No se seleccionaron audios');
            }
        }*/

        public transcribir() {
            //console.log("seleccionados", this.selected);
            let params = `fechaInicial=${this.filtroFechaInicial}&fechaFinal=${this.filtroFechaFinal}`
            //for (let item of this.cc_transcript) {
            //    params += `&callCenter[]=${item}`
            //}
            //for (let item of this.cp_transcript) {
            //    params += `&campania[]=${item}`
            //}
            //for (let item of this.ag_transcript) {
            //    params += `&agente[]=${item}`
            //}
            for (let item of this.filtro_transcript) {
                params += `&filtro[]=${item}`
            }
            //let mensaje = `Se comenzo con la transcripcion de los ${this.total_transcript} audios`;
            let mensaje = Util.replaceTextWith(this.$t("DataTableSelection.mensaje") as string, "%s", this.total_transcript.toString())
            //this.cc_transcript = [];
            //this.cp_transcript = [];
            //this.ag_transcript = [];
            this.filtro_transcript = [];
            this.disable_transcript = false;
            this.total_transcript = 0;
            this.dialog_transcrip = false;
            Util.showMessage(
                mensaje,
                Icon.SUCCESS
            );
            internet
                .newRequest()
                .post(`biblioteca/transcribirAudios?${params}`)
                .then((response) => {
                    //console.log(response.data);
                    this.disable_transcript = true;
                })
        }

        public listenClickRow(payload: IDataTable) {
			this.$emit("audios", payload);
		}

        public sendToAudio(payload: IDataTable){
            this.$router.push({
				name: 'detailLibrary',
                params: {
                    destinatario: "false",
                    fechaInicial: this.filtroFechaInicial,
                    fechaFinal: this.filtroFechaFinal,
                    callCenter: payload["Call_center"], 
                    rutaSftp: payload["Ruta_sftp"]
                    // campania: payload["Campania"], 
                    // agente: payload["Agente"]
                }
			});
        }
        public getTextCountElementPerPage(
			to: number,
			end: number
		): string {
			let text = Util.replaceTextWith(
				this.countElementPage as string,
				'$1',
				to.toString()
			);
			text = Util.replaceTextWith(
				text,
				'$2',
				end.toString()
			);
			return text;
		}

        public reemplazarTexto(texto:string, patron:string, agregado: string) {
            return Util.replaceTextWith(texto, patron, agregado);
        }
    }
