























































































































































import { Component, Vue } from 'vue-property-decorator';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { IDataTable } from '@/model/main/IDataTable';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';

@Component({
		name: 'MainPorcentajeSilencios',
		components: {

		},
	})
	export default class MainPorcentajeSilencios extends Vue {
        //General
        public radio = false;
		public elementPerPage = this.$t("DataTable.elementPerPage");
        public countElementPage = this.$t("DataTable.countElementPage");
       //relleno tabla para audios con menor tiempo de silencio
       public header: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agente', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudio'},
			{ text: 'Campaña', value: 'campania'},
			{ text: 'Tipo llamada', value: 'tipoLlamada'},
            { text: 'Tiempo Silencio (seg)', value: 'tiempoSilencio'},
		]
        public rows: Array<IDataTable> = [];
        //relleno tabla para audios con mayor tiempo de silencio
        public headerMayorSilencio: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agenteMayorSilencio', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudioMayorSilencio'},
			{ text: 'Campaña', value: 'campaniaMayorSilencio'},
			{ text: 'Tipo llamada', value: 'tipoLlamadaMayorSilencio'},
            { text: 'Tiempo Silencio (seg)', value: 'tiempoMayorSilencio'},
		]
        public rowsMayorSilencio: Array<IDataTable> = [];
        //relleno tabla para audios con menor tiempo de silencio TAS
        public headerTASmin: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agenteMaxTAS', align: 'start'},
			{ text: 'Nombre Audio', value: 'agenteMinTAS'},
			{ text: 'Campaña', value: 'campaniaMinTAS'},
			{ text: 'Tipo llamada', value: 'tipoLlamadaMinTAS'},
            { text: 'Tiempo Silencio (seg)', value: 'tiempoMinTAS'},
            { text: 'TAS', value: 'TASmin'},
		]
        public rowsTASmin: Array<IDataTable> = [];
        //relleno tabla para audios con mayor tiempo de silencio TAS
        public headerTASmax: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agenteMaxTAS', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudioMaxTAS'},
			{ text: 'Campaña', value: 'campaniaMaxTAS'},
			{ text: 'Tipo llamada', value: 'tipoLlamadaMaxTAS'},
            { text: 'Tiempo Silencio (seg)', value: 'tiempoAudioMaxTAS'},
            { text: 'TAS', value: 'TASmax'},
		]
        public rowsTASmax: Array<IDataTable> = [];

        
            mounted(){
            this.obtenerLlamadasResultadosTAS()      
            this.obtenerLlamadasTAS()
        }
        
        public obtenerLlamadasResultadosTAS(){
            const request_1 = internet.newRequest().get('/monitoreos/getResultadoSilencio?orden=1');
            const request_2 = internet.newRequest().get('/monitoreos/getResultadoSilencio?orden=-1');
			Util.waitForPromises([request_1, request_2])
            .then((result) => {
                let response1 = result[0]
                let response2 = result[1]
                const list: Array<IDataTable> = []
            for (let item of response1.data){
                console.log("aqui", item)
                let resultadoEvaTiempo = {
                    id_tabla: item.nombreAudio + item.agente,
                    agente: item.agente,
                    nombreAudio: item.nombreAudio,
                    campania: item.campania,
					tipoLlamada: item.tipoLlamada,
                    tiempoSilencio: item.tiempoSilencio
                }
                list.push(resultadoEvaTiempo)
            }
            console.log(list)
            this.rows = list 

            const list2: Array<IDataTable> = []
            for (let item of response2.data){
                console.log("aqui2", item)
                let resultadoTiempoMaxSilencio = {
                    id_tablaMayorSilencio: item.nombreAudio + item.agente + 'tiempoSilencio',
                    agenteMayorSilencio: item.agente,
                    nombreAudioMayorSilencio: item.nombreAudio,
                    campaniaMayorSilencio: item.campania,
					tipoLlamadaMayorSilencio: item.tipoLlamada,
                    tiempoMayorSilencio: item.tiempoSilencio
                }
                list2.push(resultadoTiempoMaxSilencio)
            }
            console.log(list2)
            this.rowsMayorSilencio = list2 


            })
        }


        public obtenerLlamadasTAS(){
            const request_1 = internet.newRequest().get('/monitoreos/getDuracionLlamadasTAS?orden=1');
            const request_2 = internet.newRequest().get('/monitoreos/getDuracionLlamadasTAS?orden=-1');
			Util.waitForPromises([request_1, request_2])
            .then((result) => {
                let response1 = result[0]
                let response2 = result[1]
                const list: Array<IDataTable> = []
            for (let item of response1.data){
                console.log("aqui3", item)
                let resultadoEvaTiempo = {
                    id_tablaMinTAS: item.agente + item.nombreAudio + 'min',
                    agenteMinTAS: item.agente,
                    nombreAudioMinTAS: item.nombreAudio,
                    campaniaMinTAS: item.campania,
                    tipoLlamadaMinTAS: item.tipoLlamada,
					tiempoAudioMinTAS: item.tiempoAudio,
                    TASmin: item.tas
                }
                list.push(resultadoEvaTiempo)
            }
            console.log(list)
            this.rowsTASmin = list 

            const list4: Array<IDataTable> = []
            for (let item of response2.data){
                console.log("aqui4", item)
                let resultadoEvaTiempoMax = {
                    id_tablaMaxTAS: item.tiempoAudio + item.nombreAudio + 'max',
                    agenteMaxTAS: item.agente,
                    nombreAudioMaxTAS: item.nombreAudio,
                    campaniaMaxTAS: item.campania,
                    tipoLlamadaMaxTAS: item.tipoLlamada,
					tiempoAudioMaxTAS: item.tiempoAudio,
                    TASmax: item.tas
                }
                list4.push(resultadoEvaTiempoMax)
            }
            console.log(list4)
            this.rowsTASmax = list4 


            })
        }

        public getTextCountElementPerPage(
            to: number,
			end: number
            ): string {
                let text = Util.replaceTextWith(
                    this.countElementPage as string,
                    '$1',
                    to.toString()
                    );
                    text = Util.replaceTextWith(
                        text,
                        '$2',
                        end.toString()
                        );
                        return text;
                    }

    }

