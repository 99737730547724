
























































































































































































	import { Component, Vue } from 'vue-property-decorator';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	import { internet } from '@/utils/Internet';
	import { IHeaderTable } from '@/model/main/IHeaderTable';
	import { IDataTable } from '@/model/main/IDataTable';
	import toolbarFilterMonitoreo from '@/components/monitoreo/toolbarFilterMonitoreo.vue';
	import dataTableSelection from '@/components/monitoreo/dataTableSelection.vue';
	import { IItemSelect } from '@/model/util/IItemSelect';
	import Util from '@/utils/Util';
	import MainCalidadTAS from '@/views/monitoreos/MainCalidadTAS.vue';
	import MainDuracionLlamados from '@/views/monitoreos/MainDuracionLlamados.vue';
	import MainPorcentajeSilencios from '@/views/monitoreos/MainPorcentajeSilencios.vue';

	@Component({
		name: 'MainAdministration',
		components: {
			AppContainerBase,
			toolbarFilterMonitoreo,
			dataTableSelection,
			MainCalidadTAS,
			MainDuracionLlamados,
			MainPorcentajeSilencios,
		},
	})
	export default class MainAdministration extends Vue {
		public tab = 'option-1'
		public tabs = null;
		public header: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agente', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudio'},
			{ text: 'Campaña', value: 'campania'},
			{ text: 'Resultados (seg)', value: 'tiempoAudio'},
		]
		public tabItems: Array<IItemSelect> = [
			{
				text: this.$t("monitoreos.duracionLlamadas") as string,
                value: MainDuracionLlamados,
				icon: 'mdi mdi-phone'
			},
			{
				text: this.$t("monitoreos.calidadTAS") as string,
                value: MainCalidadTAS,
				icon: 'mdi mdi-chart-bar'
			},
            {
				text: this.$t("monitoreos.porcentajeSilencios") as string,
                value: MainPorcentajeSilencios,
				icon: 'mdi mdi-volume-off'
			},
			//{
			//	text: this.$t("MainTerminosColoquiales.title") as string,
            //    value: MainTerminosColoquiales
			//},
		];
		public rows: Array<IDataTable> = [];
		public elementPerPage = this.$t("DataTable.elementPerPage");
        public countElementPage = this.$t("DataTable.countElementPage");

		public getTextCountElementPerPage(
            to: number,
			end: number
            ): string {
                let text = Util.replaceTextWith(
                    this.countElementPage as string,
                    '$1',
                    to.toString()
                    );
                    text = Util.replaceTextWith(
                        text,
                        '$2',
                        end.toString()
                        );
                        return text;
                    }
	}
