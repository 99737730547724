var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.rows,"headers":_vm.headers,"single-select":_vm.singleSelect,"item-key":"Item_key","no-data-text":_vm.noDataText,"footer-props":{
			'items-per-page-text': _vm.elementPerPage,
		}},scopedSlots:_vm._u([{key:"item.Accion",fn:function(ref){
		var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.sendToAudio(item)}}},[_vm._v(" mdi-magnify ")])]}},{key:"footer.page-text",fn:function(ref){
			var pageStart = ref.pageStart;
			var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}])}),_c('v-dialog',{attrs:{"width":"400","persistent":""},model:{value:(_vm.dialog_transcrip),callback:function ($$v) {_vm.dialog_transcrip=$$v},expression:"dialog_transcrip"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h7 teal lighten-2 white--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.texto_atencion)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.reemplazarTexto(_vm.texto_confirmacion, "%s", _vm.total_transcript.toString()))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"rigth":"","dark":"","color":"pink","outlined":""},on:{"click":function($event){_vm.dialog_transcrip=false}}},[_vm._v(" "+_vm._s(_vm.btn_cerrar)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rigth":"","dark":"","color":"#06B7B2","outlined":""},on:{"click":_vm.transcribir}},[_vm._v(" "+_vm._s(_vm.btn_continuar)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }