




















































































































































import { Component, Vue } from 'vue-property-decorator';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { IDataTable } from '@/model/main/IDataTable';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';

@Component({
		name: 'MainDuracionLlamados',
		components: {

		},
	})
	export default class MainDuracionLlamados extends Vue {
        public header: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agente', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudio'},
			{ text: 'Campaña', value: 'campania'},
			{ text: 'Resultados (seg)', value: 'tiempoAudio'},
		]
        //General
        public radio = false;
        public rows: Array<IDataTable> = [];
		public elementPerPage = this.$t("DataTable.elementPerPage");
        public countElementPage = this.$t("DataTable.countElementPage");
        //Tabla con maximo de segundos por audios
        public headerMax: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agenteMax', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudioMax'},
			{ text: 'Campaña', value: 'campaniaMax'},
			{ text: 'Resultados (seg)', value: 'tiempoAudioMax'},
		]
        public rowsMax: Array<IDataTable> = [];
        //Tabla con minimo de segundos por audios TAS
        public headerTASmin: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agenteMinTAS', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudioMinTAS'},
			{ text: 'Campaña', value: 'campaniaMinTAS'},
			{ text: 'Resultados (seg)', value: 'tiempoAudioMinTAS'},
            { text: 'TAS', value: 'TASmin'},
		]
        public rowsTASmin: Array<IDataTable> = [];
        //Tabla con minimo de segundos por audios TAS
        public headerTASmax: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agenteMaxTAS', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudioMaxTAS'},
			{ text: 'Campaña', value: 'campaniaMaxTAS'},
			{ text: 'Resultados (seg)', value: 'tiempoAudioMaxTAS'},
            { text: 'TAS', value: 'TASmax'},
		]
        public rowsTASmax: Array<IDataTable> = [];

            

        mounted(){
            this.obtenerLlamadas()      
            this.obtenerLlamadasTAS()
        }

        public obtenerLlamadas(){
            const request_1 = internet.newRequest().get('/monitoreos/getDuracionLlamadas');
            const request_2 = internet.newRequest().get('/monitoreos/getDuracionLlamadasMax');
			Util.waitForPromises([request_1, request_2])
            .then((result) => {
                let response1 = result[0]
                let response2 = result[1]
                const list: Array<IDataTable> = []
            for (let item of response1.data){
                console.log("aqui", item)
                let resultadoEvaTiempo = {
                    id_tabla: item.tiempo_total + item.agente,
                    agente: item.agente,
                    nombreAudio: item.nombreAudio,
                    campania: item.campania,
					tiempoAudio: item.tiempoAudio
                }
                list.push(resultadoEvaTiempo)
            }
            console.log(list)
            this.rows = list 

            const list2: Array<IDataTable> = []
            for (let item of response2.data){
                console.log("aqui2", item)
                let resultadoEvaTiempoMax = {
                    id_tablaMax: item.tiempo_total + item.agenteMax,
                    agenteMax: item.agenteMax,
                    nombreAudioMax: item.nombreAudioMax,
                    campaniaMax: item.campaniaMax,
					tiempoAudioMax: item.tiempoAudioMax
                }
                list2.push(resultadoEvaTiempoMax)
            }
            console.log(list2)
            this.rowsMax = list2 


            })
        }

        public obtenerLlamadasTAS(){
            const request_1 = internet.newRequest().get('/monitoreos/getDuracionLlamadasTAS?orden=1');
            const request_2 = internet.newRequest().get('/monitoreos/getDuracionLlamadasTAS?orden=-1');
			Util.waitForPromises([request_1, request_2])
            .then((result) => {
                let response1 = result[0]
                let response2 = result[1]
                const list: Array<IDataTable> = []
            for (let item of response1.data){
                console.log("aqui3", item)
                let resultadoEvaTiempo = {
                    id_tablaMinTAS: item.agente + item.nombreAudio + 'min',
                    agenteMinTAS: item.agente,
                    nombreAudioMinTAS: item.nombreAudio,
                    campaniaMinTAS: item.campania,
					tiempoAudioMinTAS: item.tiempoAudio,
                    TASmin: item.tas
                }
                list.push(resultadoEvaTiempo)
            }
            console.log(list)
            this.rowsTASmin = list 

            const list4: Array<IDataTable> = []
            for (let item of response2.data){
                console.log("aqui4", item)
                let resultadoEvaTiempoMax = {
                    id_tablaMaxTAS: item.tiempoAudio + item.nombreAudio + 'max',
                    agenteMaxTAS: item.agente,
                    nombreAudioMaxTAS: item.nombreAudio,
                    campaniaMaxTAS: item.campania,
					tiempoAudioMaxTAS: item.tiempoAudio,
                    TASmax: item.tas
                }
                list4.push(resultadoEvaTiempoMax)
            }
            console.log(list4)
            this.rowsTASmax = list4 


            })
        }

        public getTextCountElementPerPage(
            to: number,
			end: number
            ): string {
                let text = Util.replaceTextWith(
                    this.countElementPage as string,
                    '$1',
                    to.toString()
                    );
                    text = Util.replaceTextWith(
                        text,
                        '$2',
                        end.toString()
                        );
                        return text;
                    }

    }

