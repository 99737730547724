var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Audios con mejor resultado TAS ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header,"items":_vm.rows,"height":"300px","fixed-header":"","footer-props":{
            'items-per-page-text': _vm.elementPerPage,}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',_vm._l((items),function(row){return _c('tr',{key:row.id_tabla},[_c('td',[_vm._v(_vm._s(row.agente))]),_c('td',[_vm._v(_vm._s(row.nombreAudio))]),_c('td',[_vm._v(_vm._s(row.campania))]),_c('td',[_vm._v(_vm._s(row.tipoLlamada))]),_c('td',[_vm._v(_vm._s(row.tas))])])}),0)]}},{key:"footer.page-text",fn:function(ref){
            var pageStart = ref.pageStart;
            var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}])})],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Audios con menor resultado TAS ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerPeorTAS,"items":_vm.rowsPeorTAS,"height":"300px","fixed-header":"","footer-props":{
            'items-per-page-text': _vm.elementPerPage,}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',_vm._l((items),function(row){return _c('tr',{key:row.id_tabla},[_c('td',[_vm._v(_vm._s(row.agentePeorTAS))]),_c('td',[_vm._v(_vm._s(row.nombreAudioPeorTAS))]),_c('td',[_vm._v(_vm._s(row.campaniaPeorTAS))]),_c('td',[_vm._v(_vm._s(row.tipoLlamadaPeorTAS))]),_c('td',[_vm._v(_vm._s(row.tasPeorTAS))])])}),0)]}},{key:"footer.page-text",fn:function(ref){
            var pageStart = ref.pageStart;
            var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }