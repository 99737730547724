var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-container',[_c('v-card-text',[_c('v-row',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_c('v-radio',{attrs:{"label":"Resultados audios tiempo de silencio (seg)","value":true,"color":"#06B7B2"}}),_c('v-radio',{attrs:{"label":"Resultados audios tiempo de silencio (seg) con resultados TAS","selected":true,"value":false,"color":"#06B7B2"}})],1),(_vm.radio)?_c('v-col',[_c('div',[_c('v-card-title',[_vm._v(" Audios con menor tiempo de silencio (seg) ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header,"items":_vm.rows,"height":"300px","fixed-header":"","footer-props":{
                                        'items-per-page-text': _vm.elementPerPage,}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                                        var items = ref.items;
return [_c('tbody',_vm._l((items),function(row){return _c('tr',{key:row.id_tabla},[_c('td',[_vm._v(_vm._s(row.agente))]),_c('td',[_vm._v(_vm._s(row.nombreAudio))]),_c('td',[_vm._v(_vm._s(row.campania))]),_c('td',[_vm._v(_vm._s(row.tipoLlamada))]),_c('td',[_vm._v(_vm._s(row.tiempoSilencio))])])}),0)]}},{key:"footer.page-text",fn:function(ref){
                                        var pageStart = ref.pageStart;
                                        var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}],null,false,2553080231)}),_c('v-card-title',[_vm._v(" Audios con mayor tiempo de silencio (seg) ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerMayorSilencio,"items":_vm.rowsMayorSilencio,"height":"300px","fixed-header":"","footer-props":{
                                        'items-per-page-text': _vm.elementPerPage,}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                                        var items = ref.items;
return [_c('tbody',_vm._l((items),function(row){return _c('tr',{key:row.id_tablaMayorSilencio},[_c('td',[_vm._v(_vm._s(row.agenteMayorSilencio))]),_c('td',[_vm._v(_vm._s(row.nombreAudioMayorSilencio))]),_c('td',[_vm._v(_vm._s(row.campaniaMayorSilencio))]),_c('td',[_vm._v(_vm._s(row.tipoLlamadaMayorSilencio))]),_c('td',[_vm._v(_vm._s(row.tiempoMayorSilencio))])])}),0)]}},{key:"footer.page-text",fn:function(ref){
                                        var pageStart = ref.pageStart;
                                        var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}],null,false,1175947423)})],1)]):_vm._e(),(!_vm.radio)?_c('v-col',[_c('div',[_c('v-card-title',[_vm._v(" Audios con menor tiempo de silencio (seg) y menor resultado TAS ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerTASmin,"items":_vm.rowsTASmin,"height":"300px","fixed-header":"","footer-props":{
                                            'items-per-page-text': _vm.elementPerPage,}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                                            var items = ref.items;
return [_c('tbody',_vm._l((items),function(row){return _c('tr',{key:row.id_tablaMinTAS},[_c('td',[_vm._v(_vm._s(row.agenteMinTAS))]),_c('td',[_vm._v(_vm._s(row.nombreAudioMinTAS))]),_c('td',[_vm._v(_vm._s(row.campaniaMinTAS))]),_c('td',[_vm._v(_vm._s(row.tipoLlamadaMinTAS))]),_c('td',[_vm._v(_vm._s(row.tiempoAudioMinTAS))]),_c('td',[_vm._v(_vm._s(row.TASmin))])])}),0)]}},{key:"footer.page-text",fn:function(ref){
                                            var pageStart = ref.pageStart;
                                            var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}],null,false,3767495439)}),_c('v-card-title',[_vm._v(" Audios con mayor tiempo de silencio (seg) y mayor resultado TAS")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerTASmax,"items":_vm.rowsTASmax,"height":"300px","fixed-header":"","footer-props":{
                                        'items-per-page-text': _vm.elementPerPage,}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                                        var items = ref.items;
return [_c('tbody',_vm._l((items),function(row){return _c('tr',{key:row.id_tablaMaxTAS},[_c('td',[_vm._v(_vm._s(row.agenteMaxTAS))]),_c('td',[_vm._v(_vm._s(row.nombreAudioMaxTAS))]),_c('td',[_vm._v(_vm._s(row.campaniaMaxTAS))]),_c('td',[_vm._v(_vm._s(row.tipoLlamadaMaxTAS))]),_c('td',[_vm._v(_vm._s(row.tiempoAudioMaxTAS))]),_c('td',[_vm._v(_vm._s(row.TASmax))])])}),0)]}},{key:"footer.page-text",fn:function(ref){
                                            var pageStart = ref.pageStart;
                                            var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}],null,false,3561068305)})],1)]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }